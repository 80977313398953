<template>
  <v-app class="page-home">
  </v-app>
</template>
<script>
import config from '@/config';

import {
  mapGetters
} from 'vuex'

export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  created() {
    this.checkCurrentLogin()
    if(this.currentUser.isCompliance) {
      this.$router.push('/compliance')
    }
    else {
      this.$router.push('/properties')
    }
  },
  updated() {
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin() {
      const now = Math.floor(Date.now() / 1000)
      if (!this.currentUser || !this.currentUser.isLoggedIn || !localStorage.token || now >= this.currentUser.expire) {
        window.location.href = `${config.loginUrl}?redirect_uri=${window.location.href}`;
      }
    }
  },
  mounted() {
    this.checkCurrentLogin()
  }
}
</script>